th {
  white-space: nowrap;
}
.table {
  border-collapse: separate !important;
  border-spacing: 0px 3px;
}
table.table td,
table.table th {
  border: 1px solid #f3f3f3;
  border-left: none;
  border-right: none;
}

table.table td:last-child,
table.table th:last-child {
  border: 1px solid #f3f3f3 !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: none !important;
}
table.table td:first-child,
table.table th:first-child {
  border: 1px solid #f3f3f3 !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: none !important;
}

table.table tr {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 2px;
}
table.table td:last-child,
table.table th:last-child {
  text-align: center;
  width: 100px;
}

table.table td:first-child,
table.table th:first-child :not(table.expand-area-table td) {
  padding-left: 2%;
}

.date-data {
  width: 230px;
}

th {
  font-weight: normal;
}

#action {
  overflow: visible;
}

#action .dropdown-menu{
    box-shadow: 0 0 10px 1px #f1f1f1;
    border: none;
    border-radius: 8px;
}

.dropdown-toggle::before {
  border: none  !important;
}

#action img{
    width: 20px;
    height: 20px;
    margin-right: 7px;
}

#action .dropdown-item{
    padding: 5px 10px;
}

#action .dropdown-toggle{
    cursor: pointer;
}