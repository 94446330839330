.details-form {
  bottom: 85px;
  right: 51px;
  position: absolute;
  max-height: 600px;
  max-width: 400px;
}
.chat-icon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  cursor: pointer;
}

.header {
  background-color: #083047;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px 15px;
  padding-bottom: 70px;
}

/* .chat-card{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top:60px ;
} */

.chat-content {
  height: 60%;
}
/* .main-content {
    position: relative;
    margin-left: 0 !important;
    padding: 25px 30px;
} */

.top-img {
  position: absolute;
  top: 78px;
  z-index: 9;
}

.message-handle {
  margin-top: 16px !important;
}
