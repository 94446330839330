/*table.table td:last-child,*/
/*table.table th:last-child {*/
/*  text-align: center;*/
/*  width: 130px;*/
/*}*/
.add-btn {
  background-color: #083047;
  color: white;
}
.add-btn:hover {
  color: white;
  opacity: 0.9;
}

.flow {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-btn {
  width: 60px;
  height: 30px;
  background-color: rgb(101, 117, 128);
  color: #ffffff;
}

button {
  border: none !important;
  padding: 3px 5px;
}

.fa.fa-eye {
  color: #1e4257;
}
.page-link {
  color: #999daa;
  margin: 0 3px;
  border-radius: 6px !important;
}

.page-item.active .page-link {
  background-color: #083047 !important;
  color: white;
  border-color: #083047;
}
