* {
  font-family: 'Poppins';
}
.chat-infos p {
  color: #adacb9;
  font-size: 12px;
}

.copy-btn {
  width: 60px;
  height: 30px;
  background-color: rgb(101, 117, 128);
  color: #ffffff;
}

.record-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

.record-card {
  height: 500px;
}

.record-content {
  height: 75%;
}

.chat-message--reply .chat-rev {
  flex-direction: row-reverse;
}

.date-divider {
  width: 100%;
  max-width: 100vw;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  position: relative;
}

.divider-line {
  width: 100%;
  position: absolute;
  background-color: #2a607e;
}

.divider-wrapper {
  border: 1px solid #2a607e;
  color: black;
  background-color: white;
  height: 2.2rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 1;
}

.chat-date {
  text-align: center;
  margin-bottom: 0rem;
  font-size: 13px;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.chat-history {
  scrollbar-color: #fff0 #ddd0;
  scrollbar-width: thin;
}

.chat-history:hover {
  scrollbar-color: #fff0 #ddd;
}

.chat-history::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.chat-history::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  margin: 5px auto;
}

.chat-history:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(182, 180, 180);
  border-radius: 10px;
  margin: 5px auto;
}

.summary-text-wrapper {
  width: 100%;
  min-height: 3rem;
}

.case-text-wrapper {
  width: 100%;
  max-height: 3rem;
}

.summary-text {
  min-height: fit-content;
  border-radius: 5px;
  padding: 1rem auto !important;
}

.chat-history:hover::-webkit-scrollbar-thumb {
  border-radius: 10px 10px 10px 0px;
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.5);
}

#send-email .message-container {
  width: 40px;
  position: relative;
}

#send-email .attach > * {
  position: absolute;
  right: 1%;
  bottom: 10%;
  cursor: pointer;
}

#send-email .attach img {
  border: 1px solid rgb(189, 188, 188);
  border-radius: 3px;
}

#send-email .attach input {
  width: 30px;
  height: 30px;
  right: 1%;
  bottom: 0%;
  z-index: 2;
  opacity: 0;
}

#send-email .attach .file-container {
  width: fit-content;
  height: 30px;
  right: 0%;
  bottom: -30%;
}

#send-email .modal-content {
  border: none !important;
  border-radius: 8px;
  margin-top: 5rem;
}

#send-email .modal-dialog {
  margin: 0 !important;
}
