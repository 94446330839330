@import "./assets/css/sass/style.sass"

html
  font-size: 12px

div, label
  color: #000

input::-webkit-input-placeholder
  font-size: 14px

input::-moz-placeholder
  font-size: 14px

td .circuler-image
  width: 35px
  height: 35px
  border-radius: 50%

.active-agent
  align-items: center
  display: flex

  & span
    width: 15px
    height: 15px
    display: inline-block
    border-radius: 5px
    background-color: #6dd400
    margin-right: 10px

#expand
  text-align: unset
  border: 1px solid #f3f3f3 !important

.expandedArea
  display: flex
  justify-content: space-between
  align-items: flex-start

.expand-area-table td
  border: none !important

table.expand-area-table td:last-child,
table.table th:last-child :not(.expand-area-table)
  border: none !important
  text-align: left
  width: 300px

table.expand-area-table td:first-child,
table.table th:first-child :not(.expand-area-table)
  border: none !important

.agent-name-section
  display: flex
  flex-direction: column
  flex-grow: 1px
  max-width: 45%
  flex-basis: 45%

.action
  display: flex
  direction: row
  justify-content: space-around

  max-width: 40%
  flex-basis: 40%

.ion-alert-circled
  color: #ff4343
  font-size: 24px

.ion-ios-personadd
  color: #908fea
  font-size: 24px

.agent-name
  font-size: 20px
  font-weight: bold
  color: #222222

.agent-type
  color: #707070
  font-weight: bold

.collapse-area
  display: flex
  flex-direction: row
  justify-content: flex-start

.right-section
  display: flex
  flex-direction: column
  max-width: 90%
  flex-basis: 90%
  margin-top: 1%

.image-avatar-section
  max-width: 10%
  flex-basis: 10%

  & img
    width: 70px
    height: 70px

.expanded
  background-color: #cec9c9

@media (min-width: 959px)
  .modal-dialog
    max-width: 720px
    margin: 1.75rem auto

.avatar
  vertical-align: middle
  width: 70px
  height: 70px
  border-radius: 50%

.form-control
  /* border: none; */
  /* border-bottom: 1px solid rgb(206, 212, 218); */
  /* border-radius: 0; */

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus,

select.form-control:focus
  box-shadow: inset 0 -1px 0 #ddd

select
  font-size: 12px

.rcw-header > h4
  text-align: center

.rcw-header > span
  text-align: center
  font-size: 16

.color-primary
  color: #083047

.color-bg-primary
  background-color: #083047

.scroller
  overflow: hidden
  margin: 0 auto

  &:hover
    overflow-y: scroll
