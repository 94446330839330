.broadcastHeadText
  font-style: normal
  font-weight: 600
  font-size: 15px
  line-height: 44px
  color: #000

.broadcastHead
  background: #F0F3F8

.broadcastLabel
  font-weight: 400
  font-size: 11px

.agentName
  font-size: 14px
  font-weight: 700

.time
  font-weight: 400

.message
  font-weight: 400
  font-size: 12px

.redDot
  background-color: #f00
  position: absolute
  top: 0.7rem
  right: 1.1rem
  width: 0.5rem
  height: 0.5rem
  border-radius: 1rem
