.title {
  color: #adb5bd;
}

.content {
  font-size: 36px;
  font-weight: bold;
}

.comparison-text {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
}
