.main-div{
    height: 100vh; display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forgot-page {
    /*max-width:600px ;*/
    max-width: 439px;
    width: 100%;
}

.forgot-btn-color,
.forgot-btn-color:hover {
    background: #43425d;
    color: white;
    width: 145px;
}

.login-link a {
    color: #43425d !important;
}
.resend{
    cursor: pointer !important;
}
