.dashboard-body * {
  box-sizing: border-box;
}
.dashboard-body {
  width: 100%;
  margin-left: 1.5rem;
}

.style {
  height: 530px;
}

.dash-card {
  border-radius: 15px;
}

.dash-card:not(:last-child) {
  margin-right: 25px;
}

.dash-card h6 {
  text-transform: uppercase;
}

.dash-card h4 {
  color: #6236ff;
}

.welcome-date {
  color: rgba(0, 0, 0, 0.342);
}

.dash-card p {
  font-size: 12px;
  color: #bfbfbf;
}

.dash-card .more {
  text-decoration: none;
  position: absolute;
  color: #fff !important;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  font-size: 12px;
  padding: 5px 15px;
  cursor: pointer;
}

.dash-card .more p {
  margin: 0;
  color: #fff;
}

.highcharts-credits {
  display: none;
}

.scrollme::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.scrollme::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(209, 207, 207);
  border-radius: 10px;
}

.scrollme::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(238, 238, 238);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.5);
}

.green {
  color: #306844;
}

.red {
  color: red;
}
.progress {
  border-radius: 0;
  height: 4px !important;
  margin-bottom: 1rem;
}
.firster .progress-bar {
  background-color: red;
}
.seconder .progress-bar {
  background-color: blue;
}
.thirder .progress-bar {
  background-color: #083147;
}
.fourther .progress-bar {
  background-color: lightgreen;
}

.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft {
  background-color: #f5f6f9 !important;
}

.dashboard-body .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  border-radius: 10px;
  box-shadow: none;
}

.dashboard-body .MuiTableCell-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 12px !important;
}

.dashboard-body .MuiTypography-h6,
.dash-box h4 {
  font-size: 16px !important;
}

.dashboard-body .MuiToolbar-regular {
  min-height: 20px !important;
  height: 40px !important;
  padding-left: 0px !important;
}
.dashboard-body .MuiTablePagination-toolbar {
  min-height: 20px !important;
  height: 30px !important;
  overflow: hidden;
  padding-right: 10px !important;
}

.dash-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px 2px #cecece;
}

.col-6.dash-box2 {
  max-width: 49% !important;
  flex: 30% !important;
}

.col-6.dash-box1 {
  max-width: 49% !important;
  flex: 40% !important;
}

.col-3.dept {
  min-width: 28% !important;
}

.col-2.csat {
  min-width: 20% !important;
  height: inherit;
  margin-left: 0 !important;
}

.col-2.csat h5 {
  font-size: 15px;
}

.col-2.engagement {
  min-width: 19.7% !important;
  text-align: center !important;
}

.col-2.csat,
.col-2.engagement,
.col-3.dept {
  min-height: 55vh;
  box-shadow: 0 2px 5px 2px #dddddd;
  overflow: hidden;
  scrollbar-color: #fff0 #ddd0;
  scrollbar-width: thin;
  padding-left: 2.2rem !important;
  padding-right: 2.2rem !important;
}

.col-2.csat:hover,
.col-3.dept:hover {
  scrollbar-color: #fff0 #ddd;
}

.col-2.engagement {
  scrollbar-width: none;
}

.dash-box h1.green {
  color: green;
  font-size: 2rem;
  font-weight: 700;
}

.dash-box h1.green .sm {
  font-size: 1.5rem;
}

.down-box h4 {
  margin-bottom: 1.5rem;
  color: #083147;
}

.down-box.engagement h4.h4 {
  text-align: center !important;
  width: 100%;
  font-weight: 600;
}

.down-box h1 {
  color: #083147;
  font-weight: 900;
  font-size: 5rem;
}

.down-box .last-week {
  padding-bottom: 0;
  border-bottom: 2px solid rgb(235, 235, 235);
}

.dash-box .today p {
  margin-top: 10px !important;
  text-align: center !important;
}

.dash-box .today h1 {
  text-align: center !important;
}

.dash-box p {
  font-size: 13px;
}

.dash-box.dash-box1 > div {
  margin-top: 10px;
}

.dash-box.dash-box1 > div p {
  margin-top: 0px;
}

.dashboard-body .MuiTypography-h6 {
  padding-top: 10px;
  padding-left: 15px;
  font-family: poppins;
  font-weight: 600;
  color: #083147;
}

/* .lower-dashboard {
  max-width: 1500px;
} */

.dashboard-body .MuiIconButton-root {
  padding: 5px;
}

.dashboard-body .Component-paginationSelectRoot-4 {
  display: none;
}

.down-box::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

.col-2.engagement::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.down-box::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  margin: 5px auto;
}

.down-box:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(182, 180, 180);
  border-radius: 10px;
  margin: 5px auto;
}

.down-box:hover::-webkit-scrollbar-thumb {
  border-radius: 10px 10px 10px 0px;
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.5);
}

@media screen and (max-width: 1500px) {
  .dash-box h1.green,
  .down-box h1 {
    font-size: 5rem;
  }
  .agent-status {
    min-width: 5rem !important;
  }

  .col-6.dash-box2 {
    max-width: 50% !important;
    flex: 30% !important;
  }

  .col-6.dash-box1 {
    max-width: 49% !important;
    flex: 40% !important;
  }

  .col-3.dept {
    min-width: 28% !important;
  }

  .col-2.engagement {
    min-width: 19% !important;
  }
}

.adjust {
  padding-top: 1.1rem;
}

@media screen and (max-width: 1200px) {
  .col-2.engagement {
    min-width: 15% !important;
  }
}

@media screen and (max-width: 1000px) {
  .dash-box h1.green,
  .down-box h1 {
    font-size: 4rem;
  }
  .col-6.dash-box2 {
    max-width: 46% !important;
    flex: 30% !important;
  }

  .col-6.dash-box1 {
    max-width: 49% !important;
    flex: 40% !important;
  }
  .col-3.dept {
    min-width: 28% !important;
  }

  .col-2.csat {
    min-width: 20% !important;
  }

  .col-2.engagement {
    min-width: 19% !important;
  }
  .col-2.csat,
  .col-2.engagement,
  .col-3.dept {
    margin-right: 0 !important;
    margin-right: 2px !important;
  }
}

@media screen and (max-width: 850px) {
  .dash-box h1.green,
  .down-box h1 {
    font-size: 30px;
  }
  .col-6.dash-box {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .col-6.dash-box2,
  .col-6.dash-box1,
  .col-3.dept,
  .col-2.csat,
  .col-2.engagement {
    max-width: 100% !important;
    flex: 100% !important;
  }

  .col-2.csat,
  .col-2.engagement,
  .col-3.dept {
    margin-right: 0 !important;
    margin-left: 0 !important;
    min-height: 200px !important;
    max-height: 300px !important;
  }
}
