.main-div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.remember {
  color: #43425d !important;
}

.google-auth-btn {
  text-align: center !important;
  margin: 1rem auto !important;
  width: 100% !important;
  box-shadow: none !important;
}

.google-auth-btn div {
  color: #43425d !important;
}

.google-auth-btn svg {
  height: 18px !important;
  width: 18px !important;
}

.auth {
  background-color: white !important;
  color: #43425d !important;
  border: 1px solid #43425d !important;
  height: 45px !important;
  box-shadow: none !important;
}

.divider {
  width: 100%;
  max-width: 100vw;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  position: relative;
}

.line {
  width: 100%;
  position: absolute;
  background-color: #9bb9c9;
}

.option-wrapper {
  color: black;
  background-color: white;
  height: 2.2rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 1;
}

.option {
  text-align: center;
  margin-bottom: 0rem;
  font-size: 13px;
}

.forget-password a {
  display: block;
  cursor: pointer;
}

.signin-page {
  max-width: 480px;
  width: 100%;
}

.copyright {
  /* max-width: 250px;
    width: 100%; */
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  .signin-page {
    height: 100%;
  }
}
.forgetPassword a {
  color: white !important;
  text-decoration: none !important;
}

div
  button[style*='display: block; border: 0px']
  div[style*='align-items: center']
  div[style*='text-align: left'] {
  font-size: 15px;
}

div button[style*='display: block; border: 0px'] div[style*='align-items: center'] {
  position: relative;
  top: 0px;
  left: 25%;
}
