.card-w {
  /* width: 350px; */
}

h5 {
  font-size: 16px;
}

.card-body-cus {
  padding: 10px 20px;
}

.card-head {
  border-bottom: 1px solid #eee;
}

/* .card-content {} */
.agents-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  /* padding-top: 4px; */
  /* padding-bottom: 4px; */
}

.agent-row-active::after {
  position: absolute;
  content: 'Active';
  bottom: 0;
  color: #a3f8d3;
}

.agent-connected-indicate {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #20bf15;
  margin-right: 10px;
}

.agent-connected-txt {
  color: #20bf15;
  font-weight: 600;
  font-size: 12px;
}

/* .agent-row {
    padding-top: 10px;
    padding-bottom: ;
} */

.agent-row p {
  color: #adacb9;
}

.agent-waiting-indicate {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #bf151b;
  margin-right: 10px;
}

.agent-waiting-txt {
  position: relative;
  color: #bf151b;
  font-weight: 600;
  /* line-height: 1.1; */
}

.agent-waiting-txt > span {
  /* margin-top: 10px; */
  display: block;
}

.agent-waiting-time {
  position: absolute;
  bottom: -20px;
  right: 0px;
  color: #adacb9;
  font-weight: 400;
}

.chat-card {
  /* width: 430px; */
  width: 100%;
  /* height: 40%; */
  /*height: 736px;*/
  height: 493px !important;
  /* height: auto; */
  position: relative;
}

.chat-content {
  padding-left: 20px;
  padding-right: 20px;
  /* height: 100%; */
  height: 70%;
}

.chat-action {
  padding-left: 10px;
  border-left: 1px solid #eee;
  margin-right: 10px;
}

.end-chat-btn {
  filter: blur(1px);
}

a.chat-action {
  text-decoration: none;
}

.chat-history {
  /* height: 75%; */
  height: 100%;
  /* overflow: scroll; */
  overflow: auto;
}

/* .chat-message img {
    width: 50px;
    height: 50px;
    border-radius: ;
} */

.chat-avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.chat-avatar img {
  width: 55px !important;
  height: 55px !important;
  border-radius: 50%;
  margin-top: 26%;
}

.chat-line {
  /* width: 100%; */
}

.chat-txt {
  background-color: #eee;
  border-radius: 100px 100px 100px 0px;
  padding: 10px;
  text-align: center;
  /* width: 80%; */
}

.chat-time {
  margin-top: 5px;
  font-size: 12px;
  color: #adacb9;
}

.chat-message--reply {
  flex-direction: row-reverse;
}

.chat-message--reply .chat-txt {
  border-radius: 100px 100px 0px 100px;
  background-color: #083047;
  color: #fff;
}

.chat-message--reply .chat-time {
  text-align: end;
}

.message-handle {
  /* position: absolute;
    bottom: 0;
    left: 0; */
  /* background-color: #eee;
    padding: 10px; */
  /*padding: 10px;*/
  /* margin-top: -50px; */
  /*margin-top: 45px;*/
  margin-top: 8px !important;
  z-index: 100;
  width: 384px;
}
.agents-head {
  /*display: grid;*/
  /*grid-auto-columns: 1fr 1fr;*/
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.handle-content {
  /* position: absolute;
    bottom: 0;
    left: 0; */
  /* background-color: #f2f2f2; */
  /*background-color: #F5F6FA;*/

  /*padding: 12px 12px 5px 12px;*/
  border-radius: 4px;
  /*border: 2px solid red;*/
}

.handle-content input[type='text'] {
  border: 0;
  width: 100%;
  background: transparent;
  color: #7aacfe;
}

.handle-content input[type='text']:focus {
  outline: none;
}

.handle-content input[type='text']::placeholder {
  color: #7aacfe;
  font-size: 16px;
}

.handle-content input[type='text'],
.handle-content input[type='text']::placeholder {
  font-size: 14px;
}

.handle-buttons {
  margin-top: 10px;
}

.handle-attach {
  text-decoration: none;
  color: #d9d9d9;
  font-size: 26px;
  transform: rotate(45deg);
  margin-left: 5px;
}

.handle-attach i {
  /* display: inline-block; */
  transform: rotate(45deg);
}

.chat-interaction {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #7aacfe;
  border-radius: 5px;
}

.chat-incoming {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.chat-incoming img {
  width: 100%;
  height: 100%;
}

.chat-out {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
}

.chat-out img {
  width: 100%;
  height: 100%;
}

.transaction-list {
  background-color: #eee;
  height: 68%;
  overflow: auto;
}

.transaction-title {
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
}

.list-link {
  text-decoration: none;
  color: #000;
}

.list-link:hover {
  text-decoration: none;
  color: #000;
}

.list-card {
  padding: 4px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

.list-info {
  border-left: 4px solid steelblue;
  padding-left: 8px;
}

.list-heading {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.list-number {
  margin: 0;
  font-size: 12px;
  color: #cccccc;
}

.list-data {
  font-size: 14px;
  font-weight: 600;
}

.list-data i {
  color: #cccccc;
}
/* 718040 F8BC48 3C3C88 5C9895 */

.brc-0 {
  border-color: #8f406e;
}

.brc-1 {
  border-color: #3f85c7;
}

.brc-2 {
  border-color: #718040;
}

.brc-3 {
  border-color: #f8bc48;
}

.brc-4 {
  border-color: #3c3c88;
}

.brc-5 {
  border-color: #5c9895;
}

.brc-6 {
  border-color: #c46e2b;
}

/* #083047 */
