.team {
    font-family: "Poppins" !important;
}

.fas.fa-trash-alt{
    color: #D22D2D;
}
.add-btn {
    background-color: #083047;
    color: white;
}
.add-btn:hover{
    color: white;
    opacity: 0.90;
}

.message div{
    float: left;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
.messages div{
    float: left;
    max-width: 500px;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: normal;

}

/*td, th{*/
/*    white-space: nowrap;*/
/*    min-width: 40x;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*}*/

.card-title {
    font-size: 16px !important;
    text-transform: uppercase;
    color: #083047;
}

.MuiTableBody-root {
    font-size: 13px !important;
}

.MuiTypography-h6 {
    font-family: "Poppins" !important;
    font-size: 18px !important;
}

.team-input::placeholder {
    font-size: 12px;
}


