* {
  font-family: 'Poppins';
}
.sidebar {
  height: 100%;
  width: 290px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #083047;
  z-index: 100;
  border-radius: 0px 0px 35px 0px;
  display: flex;
  flex-direction: column;
}

.sidebar-toggle {
  font-size: 32px;
  position: absolute;
  right: 10%;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
}

.sidebar-toggle:hover {
  color: #fff;
}

.sidebar-header {
  width: 100%;
  margin-top: 55px;
}

.sidebar-header .user-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.sidebar-header .user-content.user-content-sidestate {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-left: 55px !important;
}

.sidebar-header .user-content img {
  max-width: 70px;
  border-radius: 10px;
  padding: 0;
  margin: 0;
}

.sidebar-header .user-name {
  display: block;
  letter-spacing: -1px;
}
.sidebar-header button.btn.btn-secondary {
  padding: 0;
  border: none;
}
.sidebar-header button.btn.btn-secondary:focus {
  box-shadow: none;
  outline: none;
}

.sidebar-header .user-info {
  color: #fff;
  display: inline-block;
}

.sidebar-header .user-info .user-email {
  color: #768c98;
  letter-spacing: -1px;
}

.sidebar-header .dropdown-menu {
  min-width: 5rem;
}

.sidebar-menu {
  margin-top: 32px;
}

.sidebar-menu ul {
  list-style-type: none;
}

.sidebar-item {
  border-left: 4px solid transparent;
  margin-bottom: 18px;
}

.sidebar-link {
  text-decoration: none !important;
  color: #768c98;
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;
}

.sidebar-icons {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  display: block;
}

.icons-light {
  display: none;
}

.sidebar-item:hover .sidebar-link {
  color: #fff;
}

.sidebar-item:hover {
  border-color: #fff;
}

.sidebar-item:hover .icons-dark {
  display: none;
}

.sidebar-item:hover .icons-light {
  display: block;
}

.sidebar-active {
  border-color: #fff;
}

.sidebar-active .sidebar-link {
  color: #fff;
}

.sidebar-active .icons-dark {
  display: none;
}

.sidebar-active .icons-light {
  display: block;
}

.main-content {
  position: relative;
  margin-left: 290px;
  padding-bottom: 25px;
  padding-right: auto;
  overflow-x: hidden;
  max-width: 1800px;
}

.hovered {
  background-color: rgba(8, 48, 71, 0.5);
  position: absolute;
  left: 50px;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  white-space: nowrap;
  font-weight: bold;
}

.MuiTableCell-root {
  font-family: poppins !important;
}
.MuiTableCell-head {
  font-family: poppins !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
}
