
table.table td:last-child,
table.table th:last-child {
  text-align: center;
  width: 130px;
}

button {
  border: none;
  padding: 3px 5px;
}

.fa.fa-eye {
  color: #1e4257;
}
.page-link{
    color: #999DAA;
    margin: 0 3px;
    border-radius: 6px  !important;
}


.page-item.active .page-link {
    background-color: #083047  !important;
    color: white;
    border-color: #083047;

}
.view{
    cursor: pointer;
    color: gray;
    text-decoration: none !important;

}
.view a:hover{
    text-decoration: none !important;
}

[data-column-id="agent"].rdt_TableCol {
  margin-left: 15px;
}

[data-column-id="email"].rdt_TableCol {
  margin-left: 10px;
}
