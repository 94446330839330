.chat-icon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  cursor: pointer;
}

.details-form {
  bottom: 80px;
  right: 51px;
  position: absolute;
  width: 350px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.header {
  background-color: #083047;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
}

.show-me {
  max-height: 650px;
}
.form-style {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* .main-content {
  position: relative;
  margin-left: 0 !important;
  padding: 25px 30px;
} */

textarea {
  resize: none;
}
