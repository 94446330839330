.fas.fa-trash-alt {
  color: #d22d2d;
}

.message div {
  float: left;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.messages div {
  float: left;
  max-width: 500px;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: normal;
}

.add-btn:hover {
  color: white !important;
}

.setting-wrapper {
  width: 100% !important;
  max-width: 1155px !important;
}

.setting-sidebar-menu {
  width: 100%;
  max-width: 440px;
  background-color: white;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  color: #083047;
}

.nav-wrapper {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  text-align: center;
}

.sidebar-list {
  height: 100%;
  width: 100%;
}

.sidebar-list .nav-item {
  width: 100%;
  height: 39px;
  list-style-type: none;
  margin: 0%;
  font-size: 15px;
  cursor: pointer;
  padding: 0;
}

.nav-list {
  justify-content: space-between;
  display: flex;
}

.sidebar-list .nav-item:hover {
  color: rgba(0, 0, 0, 0.336);
}

.widget-settings {
  width: 590px;
  background-color: white;
  margin: auto;
  border-radius: 10px;
  color: #083047b4;
}

.form-input {
  padding: 17px !important;
  width: 500px !important;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

.time-control {
  width: 97px;
}

.time-control:focus .time-control:active {
  border: 2px solid black !important;
}

.sdk-wrapper {
  width: inherit;
  padding-bottom: auto;
}
