.avatar {
  vertical-align: middle;
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

*{
  font-family: "Poppins";
  font-weight: 300;
}

.label-title {
  font-size: 15px!important;
}

.textbox {
  height: 30px!important;
  font-size: 13px!important;
}

.profile-content form {
    width: 50%;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 4px #E5E7EB ;
  }

  .profile-details{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 4px #E5E7EB ;
  }

  .profile-container h3{
    color: #AAAAAA;
    font-size: 16px;
  }

  .profile-container h3 > span{
    color: #083047;
  }
  
  .profile-header {
    padding: 10px 0px;
  }
  
  
  .btn-outline-dark:hover {
    color: white !important;
  }
  
  #profile-info,
  #change-password {
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .changePassword-header {
    padding: 10px 0px;
    border-top: 1px solid #9CACB5;
  }