.report * {
  box-sizing: border-box;
}

.report .orange h4,
.report .orange h2 {
  background-color: #f29111;
  color: #f29111;
}

.report .purple h4,
.report .purple h2 {
  background-color: #962793;
  color: #962793;
}

.report .blue h4,
.report .blue h2 {
  background-color: #3c2796;
  color: #3c2796;
}

.report .pink h4,
.report .pink h2 {
  background-color: #f02289;
  color: #f02289;
}

.report .green h4,
.report .green h2 {
  background-color: #306844;
  color: #306844;
}

.report .MuiTableCell-head {
  font-size: 12px !important;
}

.report .MuiTableRow-root:last-child td {
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: none !important;
}

.report-top {
  /* height: 120px; */
  flex: 20% !important;
  max-width: calc(20% - 20px) !important;
  margin: auto 10px;
  box-shadow: 0 2px 5px 2px #d8d8d8;
  border-radius: 15px;
  background-color: #fff;
  padding: 10px 23px;
}

.report-top h4.h4 {
  font-size: 14px !important;
  font-weight: 500;
  color: #fff;
  flex: 1;
  /* background-color: red; */
  width: 100%;
  border-radius: 15px 15px 0 0;
  text-align: center;
}

.report-top h2 {
  flex: 2;
  font-size: 40px;
  font-weight: 500;
  background-color: #fff !important;
}

.report-mid {
  box-shadow: 0 2px 5px 2px #d8d8d8;
  margin: auto 10px;
  border-radius: 15px;
  background-color: #fff;
  padding-bottom: 2rem;
  width: 100%;
  min-height: 400px;
}

.doughnut-chart {
  width: 300px !important;
  height: 300px !important;
  margin: auto;
}

.line-chart {
  height: 500px !important;
  background-color: #fff;
}

@media screen and (max-width: 1500px) {
  .report-mid {
    height: 340px;
    max-height: 340px;
  }
}

.report-mid.col-3.last-col {
  margin-right: 0;
}

.report-mid.col-6 {
  max-width: calc(40% - 20px) !important;
  flex: 40% !important;
}

.report-mid.col-3 {
  max-width: calc(30% - 20px) !important;
  flex: 20% !important;
}

.report-mid.map > div {
  height: 340px !important;
  width: 100% !important;
}

.report-mid.map .highcharts-container {
  border-radius: 15px;
  height: 100% !important;
  width: 100% !important;
}

.report-mid.map rect.highcharts-background {
  height: 100px !important;
  width: 100% !important;
}

.report-mid.map .highcharts-root,
svg.highcharts-root {
  height: 100% !important;
  width: 100% !important;
}

.report .highcharts-zoom-in path,
.report .highcharts-zoom-out path {
  fill: #fff;
}

.report .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  border-radius: 15px;
  box-shadow: none;
  height: 100%;
  overflow: auto;
}

.report .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded::-webkit-scrollbar {
  width: 0px;
  height: 0px !important;
}

.MuiTable-root *::-webkit-scrollbar {
  width: 0px;
  height: 0px !important;
}

.report .MuiTypography-h6 {
  font-size: 16px !important;
  text-transform: uppercase;
  color: #083147;
  padding-top: 10px;
  font-family: poppins;
  font-weight: 600;
  color: #083147;
  padding-left: 15px;
}

.report .MuiToolbar-regular {
  min-height: 20px !important;
  height: 40px !important;
  padding-left: 0px !important;
}

.report .MuiTablePagination-toolbar {
  min-height: 20px !important;
  height: 40px !important;
  padding-right: 10px;
}

.report-bottom .MuiTablePagination-toolbar {
  padding-right: 20px;
}

.report .MuiIconButton-root {
  padding: 0px;
}

.report .MuiTableCell-root {
  padding: 10px;
}

.report .MuiTableCell-body {
  font-size: 13px !important;
}

.report .Component-paginationSelectRoot-4 {
  display: none;
}

.report-bottom {
  box-shadow: 0 5px 8px 2px #dadada;
  border-radius: 15px;
  background: #fff;
}

.report-bottom .MTableToolbar-root-5 {
  display: none;
}

.report-filter {
  background: #fff0;
}

.report-filter .form-group label {
  font-size: 16px !important;
}

.report-filter select.form-control,
.report-filter input.form-control {
  height: 35px !important;
  font-size: 13px !important;
  margin-top: 10px;
  background: #fff0;
}

.report .availability .progress {
  border-radius: 2px;
  height: 12px !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  background-color: orange;
}

@media screen and (max-width: 950px) {
  .report-top h4.h4 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 800px) {
  /* .report-top h4.h4 {
      font-size: 12px !important;
    } */
  .report-top {
    height: 120px;
    flex: 40% !important;
    max-width: calc(50% - 20px) !important;
    margin-top: 10px;
  }

  .report-mid.col-6 {
    max-width: calc(50% - 20px) !important;
    flex: 60% !important;
  }

  .report-mid.col-3 {
    max-width: calc(50% - 20px) !important;
    flex: 50% !important;
  }
}

@media screen and (max-width: 650px) {
  .report-mid.col-6 {
    max-width: calc(100% - 20px) !important;
    flex: 100% !important;
  }

  .report-mid.col-3 {
    max-width: calc(100% - 20px) !important;
    flex: 100% !important;
  }

  .report-filter.col-3 label {
    font-size: 12px !important;
  }
  .report-filter select.form-control {
    height: 25px !important;
    width: 25px !important;
    font-size: 13px !important;
    margin-top: 10px;
  }
  .report-bottom .MuiTableCell-head {
    font-size: 12px !important;
  }
}
