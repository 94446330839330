.wrapper {
  width: 100%;
  min-height: 400px;
  border-radius: 15px;
  background-color: #fff;
  margin-top: 2rem;
}

.title-wrapper {
  width: 100%;
  height: 70px;
  padding-left: 1rem;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
}

.title-heading {
  font-weight: bold;
}

.sub-heading {
  font-size: 11px;
}

.title-heading,
.sub-heading {
  margin-bottom: 1px;
}

.calls-chart {
  margin-top: 2rem;
}
