.tab-container {
  width: 590px;
  background-color: white;
  margin: 0 auto;
  border-radius: 10px;
  color: #083047b4;
  min-height: 900px;
  /* height: auto; */
}

.tab-wrapper {
  height: 100%;
}