.loading-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.loader-wrapper {
  width: 200px;
  height: 600px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
