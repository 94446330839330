button.close {
  font-size: 40px;
  color: #000000;
}

.css-1s2u09g-control {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0px !important;
}

.css-1pahdxg-control {
  border-bottom: 1px solid #DFDFDF !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover .css-1pahdxg-control:focus {
  border-bottom: 1px solid #DFDFDF !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.agent-form .form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dfdfdf;
  margin-top: 20px;
}

.modal-header {
  border: none;
  padding: 10px 20px 0 0;
}

.modal-header .close {
  padding: 10px 20px 0 0;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  color: #083047;
  padding: 0;
}

.menu-cover span {
  font-weight: 600;
}

.menu-cover .action-opened {
  background-color: #fff;
  z-index: 2;
  position: absolute;
  top: 25px;
  right: -5%;
  border: 1px solid rgb(172, 171, 171);
  border-radius: 3px;
  padding-top: 10px;
  width: 90px;
  height: 70px;
}

.menu-cover .action-opened.row-bottom {
  top: -80px;
}

.Agents .MuiTableBody-root tr:hover,
.team .MuiTableBody-root tr:hover {
  background-color: rgb(241, 241, 241);
}
