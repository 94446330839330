.top-nav{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.dropdown-toggle::after{
    margin-left: 45px !important;
}

.filter{
    background: white;
    padding: 7px;
    border-radius: 8px;
    margin-left: 20px;
    display: inline-block;
}

.red-dot {
    height: 6px;
    width: 6px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 2px;
    box-shadow: 0px 0px 0px 2px #f0f3f8;
}

.top-nav .dropdown-toggle.notification::after{
content: none;
}

.top-nav .dropdown-menu{
    left: auto !important;
    box-shadow: 6px 4px rgb(156,172,181, 0.3);
    border: none;
    min-width: 350px;
    transform: translate3d(-315px, 0px, 0px) !important;
}

a.view-all:hover{
    color: #6b7ec1 !important;
}

.top-nav{
    cursor: pointer;
}
