@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf');
}

/* Root Styles */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a.custom {
  color: #43425d;
}

.btn-login-clr,
.btn-login-clr:hover {
  background: #43425d;
  color: white;
  width: 100%;
}

.btn-primary-clr {
  background-color: #083047;
  color: white;
}
body {
  box-sizing: border-box;
  background-color: #f0f3f8;
  font-family: 'Poppins';
}

a.more {
  color: #a7b5ff !important;
  cursor: pointer;
  text-decoration: none;
}
table {
  width: auto;
}

table td,
table th {
  padding: 2px 10px;
}
.no-card-border {
  border: 0;
}

.btn-c-darkblue {
  background-color: #083047;
  color: #fff;
}

.btn-c-darkblue:hover,
.btn-c-darkblue:active {
  background-color: #083047 !important;
  border-color: #083047 !important;
  color: #fff;
}

/* Global Styles */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content {
  border-radius: 0px;
}

.head-title {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex-basis: 60%;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.header-part {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}
