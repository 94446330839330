.security-container {
  width: 100%;
  height: 100%;
  margin: 2rem;
  display: flex;
  text-align: center;
  padding: 0;
}

.security-wrapper {
  height: inherit;
  width: inherit;
  text-align: center;
}
